<template>
  <div v-if="hasAllPerms(['activities.view_coreactivityinscription', 'payments.view_payment'])">
    <page-header :title="title" icon="fas fa-chess-knight" :links="getLinks()">
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="activity" v-show="!isLoading(loadingName)">
      <b-row>
        <b-col>
        </b-col>
        <b-col cols="4" class="text-right no-print" v-if="activity.activityType">
          <b-form-checkbox v-model="showByType" @change="refresh()">
            <b>{{ activity.activityType.name }} {{ activity.schoolYear.name }}</b>
          </b-form-checkbox>
          <div class="help-text2" style="margin-top: -8px; margin-bottom: 8px;">
            si coché, toutes les activités du type sont prises en compte
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="members-col" ref="excelMe">
          <div ref="printMe">
            <div class="hide-here"><b>{{ activity.name }} - {{ activity.schoolYear.name }}</b></div>
            <table class="table table-striped text-center">
              <tr v-if="showAdhesions">
                <th></th>
                <th class="primary-col" colspan="6">Inscriptions</th>
                <th class="secondary-col" colspan="5">Adhésions</th>
              </tr>
              <tr>
                <th></th>
                <th class="primary-col">Ventes</th>
                <th class="primary-col">dont Réductions</th>
                <th class="primary-col">Paiements</th>
                <th class="primary-col">Reste à payer</th>
                <th class="primary-col">Reste à facturer</th>
                <th class="primary-col">Facture</th>
                <th class="secondary-col" v-if="showAdhesions">Ventes</th>
                <th class="secondary-col" v-if="showAdhesions">Paiements</th>
                <th class="secondary-col" v-if="showAdhesions">Reste à payer</th>
                <th class="secondary-col" v-if="showAdhesions">Reste à facturer</th>
                <th class="secondary-col" v-if="showAdhesions">Facture</th>
              </tr>
              <tr v-for="entityId of inscriptionsByEntity.keys()" :key="entityId">
                <td class="text-left">
                  <div
                    class="inscriptions-list"
                    v-for="inscription of inscriptionsByEntity.get(entityId)"
                    :key="inscription.id"
                  >
                    <router-link :to="getEntityLink(entityId)">{{ inscription.fullName }}</router-link>
                    <span v-if="inscription.cancelled" class="badge badge-danger">Annulation</span>
                    <span v-if="inscription.waiting" class="badge badge-warning">En attente</span>
                    <span class="badge badge-dark" v-if="showByType">
                      {{ inscription.activity.name }}
                    </span>
                  </div>
                </td>
                <td class="primary-col">{{ getSold(entityId) | currency }}</td>
                <td class="primary-col">{{ getDiscount(entityId) | currency }}</td>
                <td class="primary-col">{{ getPaid(entityId) | currency }}</td>
                <td class="primary-col">{{ getToBePaid(entityId) | currency }}</td>
                <td class="primary-col">{{ getNonInvoiced(entityId) | currency }}</td>
                <td class="primary-col">
                  <invoice-badge
                    :invoice="invoice"
                    v-for="invoice of getActivityInvoices(entityId)"
                    :key="invoice.id"
                    allow-send
                  ></invoice-badge>
                </td>
                <td class="secondary-col" v-if="showAdhesions">{{ getAdhesionSold(entityId) | currency }}</td>
                <td class="secondary-col" v-if="showAdhesions">{{ getAdhesionPaid(entityId) | currency }}</td>
                <td class="secondary-col" v-if="showAdhesions">{{ getAdhesionToBePaid(entityId) | currency }}</td>
                <td class="secondary-col" v-if="showAdhesions">{{ getAdhesionNonInvoiced(entityId) | currency }}</td>
                <td class="secondary-col" v-if="showAdhesions">
                  <invoice-badge
                    :invoice="invoice"
                    v-for="invoice of getAdhesionsInvoices(entityId)"
                    :key="invoice.id"
                  ></invoice-badge>
                </td>
              </tr>
              <tr>
                <th></th>
                <th class="primary-col">{{ getTotalSold() | currency }}</th>
                <th class="primary-col">{{ getTotalDiscounts() | currency }}</th>
                <th class="primary-col">{{ getTotalPaid() | currency }}</th>
                <th class="primary-col">{{ getTotalToBePaid() | currency }}</th>
                <th class="primary-col">{{ getTotalNonInvoiced() | currency }}</th>
                <th></th>
                <th class="secondary-col" v-if="showAdhesions">{{ getAdhesionTotalSold() | currency }}</th>
                <th class="secondary-col" v-if="showAdhesions">{{ getAdhesionTotalPaid() | currency }}</th>
                <th class="secondary-col" v-if="showAdhesions">{{ getAdhesionTotalToBePaid() | currency }}</th>
                <th class="secondary-col" v-if="showAdhesions">{{ getAdhesionTotalNonInvoiced() | currency }}</th>
                <th v-if="showAdhesions"></th>
              </tr>
              <tr>
                <th colspan="4"></th>
                <th class="primary-col">Avoirs</th>
                <th class="primary-col">{{ getTotalToBeCredited() | currency }}</th>
                <th colspan="3" v-if="showAdhesions"></th>
                <th class="secondary-col" v-if="showAdhesions">Avoirs</th>
                <th class="secondary-col" v-if="showAdhesions">{{ getAdhesionTotalToBeCredited() | currency }}</th>
                <th></th>
              </tr>
            </table>
          </div>
        </b-col>
      </b-row>
    </div>
    <activity-extra-sales-modal
      id="bv-modal-add-extra-sales"
      :activity="activity"
      :inscriptions="rawInscriptions"
      @done="loadData()"
    >
    </activity-extra-sales-modal>
    <cancel-activity-inscriptions-modal
      v-if="activity"
      :activity="activity"
      modal-id="bv-modal-cancel-activity-inscriptions"
      @done="loadData()"
    ></cancel-activity-inscriptions-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi, openDocument } from '@/utils/http'
import store from '@/store'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import ActivityExtraSalesModal from '@/components/Activities/ActivityExtraSalesModal.vue'
import CancelActivityInscriptionsModal from '@/components/Activities/CancelActivityInscriptionsModal.vue'
import { ActivityCategory, makeActivity, makeActivityInscription } from '@/types/activities'
import InvoiceBadge from '@/components/Invoices/InvoiceBadge'
import { makeAdhesion } from '@/types/adhesions'
import { distinct } from '@/utils/arrays'
import { slugify } from '@/utils/strings'

export default {
  name: 'activity-payments',
  props: {
    activityId: [String, Number],
    youthHome: [String, Number],
  },
  mixins: [BackendMixin],
  components: {
    CancelActivityInscriptionsModal,
    ActivityExtraSalesModal,
    InvoiceBadge,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      activity: null,
      loadingName: 'activity',
      rawInscriptions: [],
      activityInscriptions: [],
      soldByEntity: new Map(),
      dicountsByEntity: new Map(),
      nonInvoicedByEntity: new Map(),
      invoicePaymentRatioByEntity: new Map(),
      adhesionsSoldByEntity: new Map(),
      nonInvoicedAdhesionsByEntity: new Map(),
      adhesionsPaymentRatioByEntity: new Map(),
      adhesions: [],
      inscriptionsByEntity: new Map(),
      showByType: false,
    }
  },
  created() {
    this.loadData()
  },
  watch: {
    activity: function(newValue, oldValue) {},
    activityInscriptions: function(newValue, oldValue) {},
  },
  computed: {
    title() {
      if (this.activity) {
        return this.activity.name + ' ' + this.activity.schoolYear.name
      } else {
        return 'Activité'
      }
    },
    isYouthHome() {
      return +this.youthHome
    },
    showAdhesions() {
      return this.activity.technicalCategory !== ActivityCategory.Event
    },
    selectorDisabled() {
      return (store.getters.editMode !== '')
    },
    editMode() {
      return (store.getters.editMode !== '')
    },
    inscriptions() {
      return this.activityInscriptions.map(
        elt => this.makeItem(elt)
      )
    },
    canCancel() {
      return this.hasPerm('activities.delete_coreactivityinscription')
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      this.startLoading(this.loadingName)
      await this.loadActivity()
      await this.loadPayments()
      await this.loadAdhesions()
      await this.loadInscriptions()
      this.endLoading(this.loadingName)
    },
    async refresh() {
      this.startLoading(this.loadingName)
      await this.loadPayments()
      await this.loadInscriptions()
      this.endLoading(this.loadingName)
    },
    async loadActivity() {
      try {
        let url = '/api/activities/activities/' + this.activityId + '/'
        if (this.isYouthHome) {
          url = '/api/youth/activities/' + this.activityId + '/'
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.activity = makeActivity(resp.data)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    makeNumberMap(items) {
      const map = new Map()
      Object.keys(items).forEach(function(key) {
        map.set(+key, items[key])
      })
      return map
    },
    async loadPayments() {
      try {
        let url = '/api/activities/activities/' + this.activityId + '/payments/'
        if (this.isYouthHome) {
          url = '/api/youth/activities/' + this.activityId + '/payments/'
        }
        if (this.showByType && this.activity.activityType) {
          url += '?by_type=' + this.activity.activityType.id
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.soldByEntity = this.makeNumberMap(resp.data['sold_by_entity'])
        this.discountsByEntity = this.makeNumberMap(resp.data['discounts_by_entity'])
        this.nonInvoicedByEntity = this.makeNumberMap(resp.data['non_invoiced_by_entity'])
        this.invoicePaymentRatioByEntity = this.makeNumberMap(resp.data['invoice_payment_ratio_by_entity'])
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadAdhesions() {
      try {
        let url = '/api/activities/activities/' + this.activityId + '/adhesions/'
        if (this.isYouthHome) {
          url = '/api/youth/activities/' + this.activityId + '/adhesions/'
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.adhesionsSoldByEntity = this.makeNumberMap(resp.data['sold_by_entity'])
        this.nonInvoicedAdhesionsByEntity = this.makeNumberMap(resp.data['non_invoiced_by_entity'])
        this.adhesionsPaymentRatioByEntity = this.makeNumberMap(resp.data['invoice_payment_ratio_by_entity'])
        this.adhesions = resp.data.adhesions.map(elt => makeAdhesion(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadInscriptions() {
      try {
        let url = '/api/activities/activities/' + this.activityId + '/inscriptions/'
        if (this.isYouthHome) {
          url = '/api/youth/activities/' + this.activityId + '/inscriptions/'
        }
        if (this.showByType && this.activity.activityType) {
          url += '?by_type=' + this.activity.activityType.id
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.rawInscriptions = []
        this.activityInscriptions = []
        let inscriptionsByEntity = new Map()
        for (const elt of resp.data.inscriptions) {
          const inscription = makeActivityInscription(elt)
          this.rawInscriptions.push(inscription)
          const item = this.makeItem(inscription)
          this.activityInscriptions.push(inscription)
          let list = []
          if (inscriptionsByEntity.has(item.entity.id)) {
            list = inscriptionsByEntity.get(item.entity.id)
          }
          list.push(item)
          inscriptionsByEntity.set(item.entity.id, list)
        }
        this.inscriptionsByEntity = inscriptionsByEntity
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      let cssClass = (this.isLoading(this.loadingName)) ? 'btn-secondary disabled' : 'btn-secondary'
      let links = []
      if (this.canCancel) {
        const cssClass2 = (this.showByType) ? 'btn-secondary disabled' : cssClass
        links.push(
          {
            id: 4,
            label: 'Annuler les inscriptions',
            callback: this.cancelInscriptions,
            icon: 'fa fa-close',
            cssClass: cssClass2,
          }
        )
      }
      links.push(
        {
          id: 1,
          label: 'Réduc. / Suppl.',
          callback: this.showExtraModal,
          icon: 'fas fa-plus-circle',
          cssClass: cssClass,
        },
        {
          id: 3,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: cssClass,
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: cssClass,
        }
      )

      return links
    },
    getPaid(entityId) {
      const ratio = (this.invoicePaymentRatioByEntity.get(entityId) || 0)
      return ratio * this.getInvoiced(entityId)
    },
    getSold(entityId) {
      return this.soldByEntity.get(entityId) || 0
    },
    getDiscount(entityId) {
      return this.discountsByEntity.get(entityId) || 0
    },
    getInvoiced(entityId) {
      return this.getSold(entityId) - this.getNonInvoiced(entityId)
    },
    getNonInvoiced(entityId) {
      return this.nonInvoicedByEntity.get(entityId) || 0
    },
    getToBePaid(entityId) {
      return this.getInvoiced(entityId) - this.getPaid(entityId)
    },
    getTotalPaid() {
      let sum = 0
      for (const entityId of this.inscriptionsByEntity.keys()) {
        sum += this.getPaid(entityId)
      }
      return sum
    },
    getTotalSold() {
      let sum = 0
      for (const entityId of this.inscriptionsByEntity.keys()) {
        sum += this.getSold(entityId)
      }
      return sum
    },
    getTotalDiscounts() {
      let sum = 0
      for (const entityId of this.inscriptionsByEntity.keys()) {
        sum += this.getDiscount(entityId)
      }
      return sum
    },
    getTotalToBePaid() {
      let total = 0
      for (const entityId of this.inscriptionsByEntity.keys()) {
        const sold = this.getInvoiced(entityId)
        const amount = this.getPaid(entityId)
        const toBePaid = sold - amount
        if (toBePaid > 0) {
          total += toBePaid
        }
      }
      return total
    },
    getTotalNonInvoiced() {
      let total = 0
      for (const entityId of this.inscriptionsByEntity.keys()) {
        total += this.getNonInvoiced(entityId)
      }
      return total
    },
    getTotalToBeCredited() {
      let total = 0
      for (const entityId of this.inscriptionsByEntity.keys()) {
        const sold = this.getSold(entityId)
        const amount = this.getPaid(entityId)
        const credit = amount - sold
        if (credit > 0) {
          total += credit
        }
      }
      return total
    },
    getAdhesionPaid(entityId) {
      return (this.adhesionsPaymentRatioByEntity.get(entityId) || 0) * this.getAdhesionInvoiced(entityId)
    },
    getAdhesionSold(entityId) {
      return this.adhesionsSoldByEntity.get(entityId) || 0
    },
    getAdhesionInvoiced(entityId) {
      return this.getAdhesionSold(entityId) - this.getAdhesionNonInvoiced(entityId)
    },
    getAdhesionNonInvoiced(entityId) {
      return this.nonInvoicedAdhesionsByEntity.get(entityId) || 0
    },
    getAdhesionToBePaid(entityId) {
      return this.getAdhesionInvoiced(entityId) - this.getAdhesionPaid(entityId)
    },
    getAdhesionTotalPaid() {
      let sum = 0
      for (const entityId of this.inscriptionsByEntity.keys()) {
        sum += this.getAdhesionPaid(entityId)
      }
      return sum
    },
    getAdhesionTotalSold() {
      let sum = 0
      for (const entityId of this.inscriptionsByEntity.keys()) {
        sum += this.getAdhesionSold(entityId)
      }
      return sum
    },
    getAdhesionTotalToBePaid() {
      let total = 0
      for (const entityId of this.inscriptionsByEntity.keys()) {
        const sold = this.getAdhesionInvoiced(entityId)
        const paid = this.getAdhesionPaid(entityId)
        const toBePaid = sold - paid
        if (toBePaid > 0) {
          total += toBePaid
        }
      }
      return total
    },
    getAdhesionTotalToBeCredited() {
      let total = 0
      for (const entityId of this.inscriptionsByEntity.keys()) {
        const sold = this.getAdhesionInvoiced(entityId)
        const amount = this.getAdhesionPaid(entityId)
        const credit = amount - sold
        if (credit > 0) {
          total += credit
        }
      }
      return total
    },
    getAdhesionTotalNonInvoiced() {
      let sum = 0
      for (const entityId of this.inscriptionsByEntity.keys()) {
        sum += this.getAdhesionNonInvoiced(entityId)
      }
      return sum
    },
    makeItem(elt) {
      return {
        id: elt.id,
        invoice: elt.invoice,
        cancelled: elt.cancelled,
        waiting: elt.waiting,
        entity: elt.entity,
        fullName: elt.individual.lastName + ' ' + elt.individual.firstName,
        activity: elt.activity,
      }
    },
    getEntityLink(entityId) {
      return { name: 'families-detail', params: { entityId: '' + entityId, }, query: { tab: 'activities', }, }
    },
    getAdhesionsInvoices(entityId) {
      let invoices = this.adhesions.filter(
        elt => elt.entity === entityId
      ).map(
        elt => elt.invoice
      ).filter(elt => !!elt)
      return distinct(invoices)
    },
    getActivityInvoices(entityId) {
      let invoices = this.inscriptionsByEntity.get(entityId).map(
        elt => elt.invoice
      ).filter(elt => !!elt)
      return distinct(invoices)
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/?landscape=1'
      const activitySlug = slugify(this.activity.name)
      const docSlug = 'paiements-' + activitySlug
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const activitySlug = slugify(this.activity.name)
      const docSlug = 'paiements-' + activitySlug
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    showExtraModal() {
      this.$bvModal.show('bv-modal-add-extra-sales')
    },
    cancelInscriptions() {
      this.$bvModal.show('bv-modal-cancel-activity-inscriptions')
    },
  },
}
</script>

<style lang="less">
td.secondary-col {
  background: #dfe5ff;
  color: #222;
}
table.table-striped tr:nth-of-type(2n+1) td.secondary-col {
  background: #baccff;
  color: #222;
}
th.secondary-col {
  background: #14224d !important;
  color: #fff;
}
th.primary-col {
  background: #2a2a2a !important;
  color: #fff !important;
}
.inscriptions-list .badge {
  margin: 0 5px;
}
.dark-mode {
  td.secondary-col {
    background: #686b77;
    color: #fff;
  }
  table.table-striped tr:nth-of-type(2n) td.secondary-col {
    background: #686b77 !important;
    color: #fff;
  }
  table.table-striped tr:nth-of-type(2n+1) td.secondary-col {
    background: #4c4e57 !important;
    color: #fff;
  }
}
</style>
